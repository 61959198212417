import React, { FC } from 'react';

import { useContractDetailsQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { isEndOfMonth } from '@hofy/helpers';
import {
    FormContainer,
    FormDateInput,
    FormModal,
    FormPriceInput,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { usePurchaseItemAndEndContract } from '../../../store/contracts/usePurchaseItemAndEndContract';

interface AssignmentPurchaseModalProps {
    assignmentId: UUID;
    contractId: UUID;
    onSuccess(): void;
}

export const AssignmentPurchaseModal: FC<AssignmentPurchaseModalProps> = ({
    assignmentId,
    contractId,
    onSuccess,
}) => {
    const { contract: contractDetails } = useContractDetailsQuery(contractId);
    const { form, isLoading, isError } = usePurchaseItemAndEndContract(
        assignmentId,
        contractId,
        contractDetails,
        onSuccess,
    );

    const purchaseFeeLabel = contractDetails?.isUnbundled
        ? 'Purchase fee - Unbundled'
        : 'Purchase fee - Bundled (Rollover price over 9 months - discount)';

    return (
        <FormModal
            width={600}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title={`Purchase item and end contract #${contractId}`} />
            <ModalContent>
                <Paragraph3 marginBottom={10}>
                    Confirm that the current item has been purchased by our customer and end contract?
                </Paragraph3>

                <Paragraph3 marginBottom={10}>Item ownership will be changed to organization</Paragraph3>
                <FormContainer>
                    <FormDateInput
                        label='End of contract time'
                        api={form.fields.endOfContractTime}
                        filterDate={isEndOfMonth}
                        isRequired
                    />
                    <FormPriceInput label='Cancellation fee' api={form.fields.cancellationFee} />
                    <FormPriceInput label={purchaseFeeLabel} api={form.fields.purchaseFee} />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Cancel' onClick={form.discard} />
                <SubmitButton label='Purchase item and end contract' />
            </ModalFooter>
        </FormModal>
    );
};
