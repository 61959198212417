import { useEffect, useState } from 'react';

import {
    hasOverlappedRegionsVariant,
    ProductPayload,
    SpecificationPayload,
    VariantPayload,
    variantPriceContainsValue,
} from '@hofy/api-admin';
import { getProductDefaultOperatingSystem, isProductConfigurable, ProductCategory } from '@hofy/api-shared';
import {
    isNotSetIf,
    isRequired,
    isRequiredIf,
    isRequiredUuid,
    useForm,
    validateArrayField,
    validator,
} from '@hofy/ui';

export const useProductForm = (onSuccess: (v: ProductPayload) => void, initial: ProductPayload) => {
    const [lockVariantPrices, setLockVariantPrices] = useState(false);

    const validateSizeAndStyle = (variants: VariantPayload[]) => {
        const emptyVariants = variants.filter(variant => !variant.size && !variant.style && variant.isActive);
        const variantsWithStyle = variants.filter(v => !!v.style).length;
        const variantsWithSize = variants.filter(v => !!v.size).length;
        const isInvalidStyles = variantsWithStyle !== variants.length && variantsWithStyle !== 0;
        const isInvalidSizes = variantsWithSize !== variants.length && variantsWithSize !== 0;
        const hasOverlappedVariant = hasOverlappedRegionsVariant(variants);

        if (isInvalidSizes) {
            return 'This product has one or more variant with a size. Cannot have a variant without a size';
        }
        if (isInvalidStyles) {
            return 'This product has one or more variant with a style. Cannot have a variant without a style';
        }
        if (emptyVariants.length > 1) {
            return 'This product has a variant that has no style and no size, that should be the only variant';
        }
        if (hasOverlappedVariant) {
            return 'This product has variants with overlapped regions. Regions that are applied to variants with the same style and size should be different';
        }
        return undefined;
    };
    const validateProductFlags = (variants: VariantPayload[], product: ProductPayload) => {
        const activeVariants = variants.filter(({ isActive }) => isActive);
        const activeNonInternalVariants = activeVariants.filter(({ isInternal }) => !isInternal);
        const hasZeroPrice = variants.some(
            variant => variant.isActive && variantPriceContainsValue(variant.price, 0),
        );
        if (product.isActive && activeVariants.length === 0) {
            return 'Active product needs at least one active variant';
        }
        if (!product.isInternal && product.isActive && activeNonInternalVariants.length === 0) {
            return 'Non-internal active product needs at least one non-internal active variant';
        }
        if (hasZeroPrice && product.category !== ProductCategory.WelcomePack) {
            return 'This product has a variant that has no price and is active. Cannot have an active variant with no price';
        }
        return undefined;
    };

    const validateVariantImage = (variants: VariantPayload[], product: ProductPayload) => {
        const inActiveImages = product.images.filter(({ isActive }) => !isActive).map(({ id }) => id);
        const activeVariants = variants.filter(({ isActive }) => isActive);

        if (activeVariants.some(v => inActiveImages.includes(v.image))) {
            return 'Active variant needs an active image';
        }

        return undefined;
    };

    const form = useForm<ProductPayload>({
        initial,
        onSubmit: onSuccess,
        validate: validator<ProductPayload>({
            category: isRequired('Category cannot be empty'),
            name: isRequired('Name cannot be empty'),
            brand: isRequired('Brand cannot be empty'),
            description: isRequired('Description cannot be empty'),
            specifications: validateArrayField<ProductPayload, 'specifications'>({
                fieldsValidator: validator<SpecificationPayload>({
                    name: isRequired('Name is required'),
                    value: isRequired('Value is required'),
                }),
            }),
            variants: (variants, product) =>
                validateArrayField<ProductPayload, 'variants'>({
                    // This is optional, this rule will apply to the array itself
                    selfRules: [
                        isRequired('At least one variant is required'),
                        validateSizeAndStyle,
                        validateProductFlags,
                        validateVariantImage,
                    ],
                    // Here you can define the rules for each item in the array
                    fieldsValidator: validator<VariantPayload>({
                        image: isRequiredUuid('Image is required'),
                        sku: isRequired('SKU is required'),
                        os: [
                            isRequiredIf(() => isProductConfigurable(product.category), 'OS is required'),
                            isNotSetIf(
                                () => !isProductConfigurable(product.category),
                                'OS must be empty for this category',
                            ),
                        ],
                    }),
                })(variants, product),
        }),
    });
    useEffect(() => {
        form.setValues({
            variants: form.values.variants.map(v => ({
                ...v,
                os: getProductDefaultOperatingSystem(form.values) ?? null,
            })),
        });
    }, [form.values.category]);

    useEffect(() => {
        const areAllVariantsInternal = form.values.variants.every(v => v.isInternal);
        const newIsInternal = form.values.isInternal || areAllVariantsInternal;
        form.setValues({
            isInternal: newIsInternal,
        });
    }, [form.values.variants, form.values.isInternal]);

    useEffect(() => {
        if (!form.values.isActive) {
            return;
        }
        form.setValues({
            variants: form.values.variants.map(v => ({ ...v, isActive: false })),
        });
    }, [form.values.isActive]);

    return {
        lockVariantPrices,
        setLockVariantPrices,
        form: form,
    };
};
