export const contractErrorI18n = {
    'api-error.invalid-contract-status.title': 'Invalid status',
    'api-error.invalid-contract-status.message': 'Cannot perform this operation',
    'api-error.invalid-contact-type.title': 'Invalid contract type',
    'api-error.invalid-contact-type.message': 'Cannot perform this operation',
    'api-error.invalid-contact-terms-and-conditions.title': 'Invalid contract terms and conditions',
    'api-error.invalid-contact-terms-and-conditions.message': 'Cannot perform this operation',
    'api-error.nothing-to-invoice.title': 'Nothing to invoice',
    'api-error.nothing-to-invoice.message': 'Cannot perform this operation',
    'api-error.update-partner-contract.title': 'Can not update partner contracts',
    'api-error.update-partner-contract.message': 'Cannot perform this operation',
    'api-error.cannot-revert-not-ended-contract.title': 'Cannot revert not ended contract',
    'api-error.cannot-revert-not-ended-contract.message': 'Cannot perform this operation',
    'api-error.cannot-revert-contract-ended-previous-months.title':
        'Cannot revert contract ended in a month previous to current',
    'api-error.cannot-revert-contract-ended-previous-months.message': 'Cannot perform this operation',
    'api-error.item-belongs-to-current-contract.title': 'Item belongs to current contract',
    'api-error.item-belongs-to-current-contract.message': 'Cannot perform this operation',
    'api-error.item-attached-to-active-assignment.title': 'Item still attached to active assignment',
    'api-error.item-attached-to-active-assignment.message': 'Cannot perform this operation',
    'api-error.item-is-not-hofy-owned.title': 'Item is not hofy owned',
    'api-error.item-is-not-hofy-owned.message': 'Cannot perform this operation',
    'api-error.item-has-no-assigned-contract.title': 'Item has no assigned contract',
    'api-error.item-has-no-assigned-contract.message': 'Cannot perform this operation',
    'api-error.contract-not-active.title': 'Contract must be active',
    'api-error.contract-not-active.message': 'Cannot perform this operation',
    'api-error.invalid-contract-type-for-store-and-reuse.title': 'Invalid contract type for store and reuse',
    'api-error.invalid-contract-type-for-store-and-reuse.message': 'Cannot perform this operation',
    'api-error.invalid-country-for-store-and-reuse.title': 'Contract country invalid for store and reuse',
    'api-error.invalid-country-for-store-and-reuse.message': 'Cannot perform this operation',
    'api-error.product-category-not-for-store-and-reuse-allowed.title':
        'Contract device category invalid for store and reuse',
    'api-error.product-category-not-for-store-and-reuse-allowed.message': 'Cannot perform this operation',
    'api-error.contract-in-use.title': 'Contract already in use',
    'api-error.contract-in-use.message': 'Cannot perform this operation',
    'api-error.contract-has-not-final-assignments.title': 'Contract has not final assignments',
    'api-error.contract-has-not-final-assignments.message': 'Cannot perform this operation',
    'api-error.cannot-end-contract-in-the-past.title': 'Cannot end contract in the past',
    'api-error.cannot-end-contract-in-the-past.message': 'Cannot perform this operation',
    'api-error.invalid-contract-payment-type-for-purchase-update.title':
        'Invalid contract payment type for purchase update',
    'api-error.invalid-contract-payment-type-for-purchase-update.message': 'Cannot perform this operation',
    'api-error.invalid-purchase-contract-status.title': 'Invalid purchase contract status',
    'api-error.invalid-purchase-contract-status.message': 'Cannot perform this operation',
    'api-error.contract-has-invoiced-entries.title': 'Contract has invoiced entries',
    'api-error.contract-has-invoiced-entries.message': 'Cannot perform this operation',
    'api-error.contract-has-invoice-entries-sold-to-two.title': 'Contract has invoice entries sold to Two',
    'api-error.contract-has-invoice-entries-sold-to-two.message': 'Cannot perform this operation',
    'api-error.invalid-country-to-collect-for-store-and-reuse.title':
        'Invalid country to collect for store and reuse',
    'api-error.invalid-country-to-collect-for-store-and-reuse.message': 'Cannot perform this operation',
    'api-error.invalid-store-reuse-cant-be-nil.title': 'Store and reuse cannot be nil',
    'api-error.invalid-store-reuse-cant-be-nil.message': 'Cannot perform this operation',
    'api-error.invalid-store-reuse-collection-option.title': 'Invalid store reuse collection option',
    'api-error.invalid-store-reuse-collection-option.message': 'Cannot perform this operation',
    'api-error.rental-contract-is-not-ready-to-be-reused.title': 'Rental contract is not ready to be reused',
    'api-error.rental-contract-is-not-ready-to-be-reused.message': 'Cannot perform this operation',
    'api-error.country-not-available-for-store-and-reuse.title':
        'Country is not available for store and reuse',
    'api-error.country-not-available-for-store-and-reuse.message': 'Cannot perform this operation',
    'api-error.undefined-store-and-reuse-delivery-zone.title': 'Undefined store and reuse delivery zone',
    'api-error.undefined-store-and-reuse-delivery-zone.message': 'Cannot perform this operation',
    'api-error.contract-being-stored-for-reuse.title': 'Contract being stored for reuse',
    'api-error.contract-being-stored-for-reuse.message': 'Cannot perform this operation',
    'api-error.enrol-asset-fail-item-inactive.title': 'Item inactive',
    'api-error.enrol-asset-fail-item-inactive.message': 'Cannot perform this operation',
    'api-error.enrol-asset-fail-hofy-obtained-from-hofy.title': 'Item obtained from hofy',
    'api-error.enrol-asset-fail-hofy-obtained-from-hofy.message': 'Cannot perform this operation',
    'api-error.fail-to-create-management-contract.title': 'Failed to create management contract',
    'api-error.fail-to-create-management-contract.message': 'Cannot perform this operation',
    'api-error.enrol-asset-fail-item-owned-by-hofy.title': 'Enrol asset fail item owned by hofy',
    'api-error.enrol-asset-fail-item-owned-by-hofy.message': 'Cannot perform this operation',
    'api-error.enrol-asset-fail-asset-already-enrolled.title': 'Enrol asset fail asset already enrolled',
    'api-error.enrol-asset-fail-asset-already-enrolled.message': 'Cannot perform this operation',
    'api-error.enrol-asset-fail-item-with-courier.title': 'Enrol asset fail item with courier',
    'api-error.enrol-asset-fail-item-with-courier.message': 'Cannot perform this operation',
    'api-error.enrol-asset-fail-asset-value-undefined.title': 'Enrol asset fail asset value undefined',
    'api-error.enrol-asset-fail-asset-value-undefined.message': 'Cannot perform this operation',
    'api-error.enrol-asset-fail-asset-addon-not-available-to-org.title':
        'Enrol asset fail not available to organization',
    'api-error.enrol-asset-fail-asset-addon-not-available-to-org.message': 'Cannot perform this operation',
    'api-error.addon-contrat-type-constraint-not-met.title': 'Addon contract type constraint not met',
    'api-error.addon-contrat-type-constraint-not-met.message': 'Cannot perform this operation',
    'api-error.addon-rental-term-constraint-not-met.title': 'Addon rental term constraint not met',
    'api-error.addon-rental-term-constraint-not-met.message': 'Cannot perform this operation',
    'api-error.addon-duration-constraint-not-met.title': 'Addon duration constraint not met',
    'api-error.addon-duration-constraint-not-met.message': 'Cannot perform this operation',
    'api-error.addon-payment-schema-constraint-not-met.title': 'Addon payment schema constraint not met',
    'api-error.addon-payment-schema-constraint-not-met.message': 'Cannot perform this operation',
    'api-error.addon-product-parent-category-constraint-not-met.title':
        'Addon product parent category constraint not met',
    'api-error.addon-product-parent-category-constraint-not-met.message': 'Cannot perform this operation',
    'api-error.addon-product-category-constraint-not-met.title': 'Addon product category constraint not met',
    'api-error.addon-product-category-constraint-not-met.message': 'Cannot perform this operation',
    'api-error.addon-product-brand-constraint-not-met.title': 'Addon product brand constraint not met',
    'api-error.addon-product-brand-constraint-not-met.message': 'Cannot perform this operation',
    'api-error.addon-asset-age-constraint-not-met.title': 'Addon asset age constraint not met',
    'api-error.addon-asset-age-constraint-not-met.message': 'Cannot perform this operation',
    'api-error.addon-platform-tier-constraint-not-met.title': 'Addon platform tier constraint not met',
    'api-error.addon-platform-tier-constraint-not-met.message': 'Cannot perform this operation',
    'api-error.invalid-addon-contract.title': 'Invalid addon contract',
    'api-error.invalid-addon-contract.message': 'Cannot perform this operation',
    'api-error.organization-contract-already-exists.title': 'Organization contract already exists',
    'api-error.organization-contract-already-exists.message':
        'A contract already exists for the selected dates',
    'api-error.organization-contract-invalid-paid-seats.title': 'Invalid paid seats',
    'api-error.organization-contract-invalid-paid-seats.message': 'Number must be positive',
    'api-error.organization-contract-monthly-price-not-found.title': 'Monthly seat price not found',
    'api-error.organization-contract-monthly-price-not-found.message':
        'No addon for duration and billing frequency',
};
