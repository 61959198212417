import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
    ContractDetailsDtoWithRental,
    contractsCacheKey,
    contractService,
    invoiceEntriesQueryKey,
    RentalSubContractDto,
} from '@hofy/api-admin';
import { DateString, Price, zeroPrice } from '@hofy/global';
import { endOfMonth, errorMap, toISODate } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

import { useGetEndContractPrediction } from './useGetEndContractPrediction';

export interface EndContractFormData {
    purchaseFee: Price;
    cancellationFee: Price;
    endOfContractTime: DateString;
}

export const useEndContract = (
    contract: ContractDetailsDtoWithRental,
    mainSubContract: RentalSubContractDto,
    onSuccess: () => void,
) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: EndContractFormData) => contractService.endContract(contract.id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [contractsCacheKey],
            });
            queryClient.invalidateQueries({
                queryKey: [invoiceEntriesQueryKey, contract.id],
            });
            onSuccess();
        },
    });

    const form = useForm<EndContractFormData>({
        initial: {
            cancellationFee: zeroPrice(mainSubContract.price.currency),
            purchaseFee: zeroPrice(mainSubContract.price.currency),
            endOfContractTime: toISODate(endOfMonth()),
        },
        validate: formState => ({
            endOfContractTime: errorMap([
                formState.endOfContractTime === undefined,
                'Final date of end of contract',
            ]),
        }),
        onSubmit: mutation.mutate,
        onDiscard: onSuccess,
    });

    const { prediction } = useGetEndContractPrediction(contract.id, form.values.endOfContractTime);

    useEffect(() => {
        if (!prediction) {
            return;
        }
        form.setValues({
            cancellationFee: prediction.cancellationFee,
            purchaseFee: prediction.purchaseFee || zeroPrice(mainSubContract.price.currency),
        });
    }, [prediction]);

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
