import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { assignmentsCacheKey, assignmentService, ContractDetailsUnionDto } from '@hofy/api-admin';
import { Currency, zeroPrice } from '@hofy/global';
import { UUID } from '@hofy/global';
import { endOfMonth, errorMap, toISODate } from '@hofy/helpers';
import { useForm } from '@hofy/ui';
import { useToast } from '@hofy/ui';

import { EndContractFormData } from './useEndContract';
import { useGetEndContractPrediction } from './useGetEndContractPrediction';

export const usePurchaseItemAndEndContract = (
    assignmentId: UUID,
    contractId: UUID,
    contractDetails: ContractDetailsUnionDto | undefined,
    onSuccess: () => void,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (endContractFormData: EndContractFormData) =>
            assignmentService.purchaseItemAndEndContract(assignmentId, endContractFormData),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                title: 'Item purchased and contract ended',
                message: 'Item and contract updated',
            });
            onSuccess();
        },
    });
    const contractCurrency = contractDetails?.rentalContract?.rolloverPrice?.currency || Currency.USD;

    const form = useForm<EndContractFormData>({
        initial: {
            cancellationFee: zeroPrice(contractCurrency),
            purchaseFee: zeroPrice(contractCurrency),
            endOfContractTime: toISODate(endOfMonth()),
        },
        validate: formState => ({
            endOfContractTime: errorMap([
                formState.endOfContractTime === undefined,
                'Final date of end of contract',
            ]),
        }),
        onSubmit: mutation.mutate,
        onDiscard: onSuccess,
    });

    const { prediction } = useGetEndContractPrediction(contractId, form.values.endOfContractTime);

    useEffect(() => {
        if (!prediction) {
            return;
        }
        form.setValues({
            cancellationFee: prediction.cancellationFee,
            purchaseFee: prediction.purchaseFee || zeroPrice(contractCurrency),
        });
    }, [prediction]);

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
